import React from 'react';
import { graphql } from 'gatsby';
import { Trans } from 'gatsby-plugin-react-i18next';
import Layout from '../layouts';
import PaymentStatus from '../components/PaymentStatus/PaymentStatus';
import RobotsNoIndex from '../components/RobotsNoIndex/RobotsNoIndex';

const PaymentFailedPage = (): React.ReactElement => (
  <Layout>
    <PaymentStatus
      title={<Trans>Payment failed, please try again.</Trans>}
      text={
        <Trans>
          If you have any difficulties, please contact telegram support.
        </Trans>
      }
    />
  </Layout>
);

export function Head() {
  return <RobotsNoIndex />;
}

export default PaymentFailedPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["translation", "paymentFailedPage"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
